@mixin mobileS {
  @media (max-width: 390px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 587px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 832px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin tabletS {
  @media (max-width: 1000px) {
    @content;
  }
}
