@font-face {
  font-family: Montserat;
  src: url("../../shared/assets/fonts/Montserrat-Regular.woff"),
    url("../../shared/assets/fonts/Montserrat-Regular.woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Montserat;
  src: url("../../shared/assets/fonts/Montserrat-Medium.woff"),
    url("../../shared/assets/fonts/Montserrat-Medium.woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
