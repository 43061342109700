@import "./reset.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./classess.scss";
@import "./mixins.scss";
@import "./funcions.scss";

body {
  font-family: "Montserat", sans-serif;
  padding: 0;
  margin: 0;
  color: $text-black;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 80px;
  margin: 0 auto;

  @include mobile {
    max-width: rem(390);
    padding: 0 12px;
  }
}
